export function classNames(...classes: (string | boolean | undefined)[]) {
	return classes.flat().filter(Boolean).join(' ');
}

export function formatCentsAsUSD(cents: number) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(cents / 100);
}
